import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const router = new VueRouter({
	routes: [{path: '/',redirect: '/index'},
		{path: '/login',meta: {title: '登录'},component: () => import("@/views/login/index.vue")},
		{path: '/index',meta: {title: '首页'},component: () => import("@/views/Home.vue"),children: [
			{path: '/main',meta: {title: '数据概览'},component: () => import("@/views/main.vue")}, 
			{path: '/sys_user',meta: {title: '用户管理'},component: () => import("@/views/common/list.vue")}, 
			{path: '/crm.group',meta: {title: '小组管理'},component: () => import("@/views/common/list.vue")},
			{path: '/sys_menu',meta: {title: '菜单管理'},component: () => import("@/views/system/menu/index.vue")},
			{path: '/system.login_log',meta: {title: '用户登录日志'},component: () => import("@/views/common/list.vue")},
			{path: '/system.exception',meta: {title: '系统异常日志'},component: () => import("@/views/common/list.vue")},
			{path: '/system.log',meta: {title: '系统操作日志'},component: () => import("@/views/common/list.vue")},
			{path: '/sys_role',meta: {title: '用户组权限'},component: () => import("@/views/common/list.vue")},
			
			{path: '/crm.platform',meta: {title: '线索平台'},component: () => import("@/views/common/list.vue")},
			
			{path: '/crm.clues',meta: {title: '线索管理'},component: () => import("@/views/common/list.vue")},
			
			{path: '/crm.customer',meta: {title: '客户列表'},component: () => import("@/views/customer/index/index.vue")},
			{path: '/crm.customer?act_id=5',meta: {title: '第三届书画'},component: () => import("@/views/customer/index/index.vue")},
			
			
			
            {path: '/crm.customer/edit',meta: {title: '客户详情'},component: () => import("@/views/customer/index/edit.vue")},
			{path: '/crm.allot_customer',meta: {title: '客户分配'},component: () => import("@/views/customer/index/allot.vue")},
			{path: '/crm.works',meta: {title: '作品管理'},component: () => import("@/views/customer/work/index.vue")},
			
			{path: '/crm.activity',meta: {title: '活动管理'},component: () => import("@/views/common/list.vue")},
			{path: '/crm.activity_user',meta: {title: '活动用户管理'},component: () => import("@/views/common/list.vue")},

			{path: '/sms.template',meta: {title: '短信管理'},component: () => import("@/views/common/list.vue")},
			
			

			]
		},
	]
}) 

//路由导航守卫
router.beforeEach((to, from, next) => {
	if (to.path === '/login') {
		next();
	} else {
		let token = window.localStorage.getItem('token');
		if (!token) {
			next('/login');
		} else {
			next()
		}
	}
})

export default router
