
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import common from '@/libs/common.js'
// import * as echarts from 'echarts'


//加载进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios';
//引入element
import ElementUI from 'element-ui';
import VueWechatTitle from 'vue-wechat-title';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/quanju.css';
// import '@/assets/css/index.css';
import '@/assets/icons/iconfont.css';
import '@/assets/icons/iconfont.js';
import request from'@/libs/request.js';

import elDragDialog from '@/dialogDrag'; //自定义dialog拖拽


Vue.use(ElementUI);
Vue.use(VueWechatTitle);
Vue.use(elDragDialog);
// Vue.prototype.$echarts = echarts
Vue.prototype.$c = common; // 公共方法
Vue.prototype.$axios = axios;

router.beforeEach((to,from,next)=>{
  if(to.path != '/login'){
      NProgress.start() // 显示进度条
      next()
    
  }else{
    NProgress.start() // 显示进度条
    next()
  }
})
router.afterEach(() => {
  NProgress.done() // 完成进度条
})

router.afterEach(() => {
  NProgress.done() // 完成进度条
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

