import axios from 'axios';
import config from "../../config.js";
import router from '../router/index.js'
import common from '@/libs/common.js'
axios.defaults.withCredentials = true;
import {Message} from 'element-ui';
import qs from 'qs';
let ADMIN_API_URL;
//线上接口
ADMIN_API_URL = config.host  + '/';
// ADMIN_API_URL = 'http://vote.qisheng101.com/';
//本地接口
// ADMIN_API_URL = 'http://base.api.cn/';
// let TOKEN_URL = 'admin/system.auth/check'
const request = {
	getAjax(url, data = {}) {
		return new Promise((resolve, reject) => {
			let token = window.localStorage.getItem('token');
			let tokenkey = window.localStorage.getItem('tokenkey');
			let header = {}
			if(token == null || tokenkey == null){
				header = {'Content-Type': 'application/json;charset=UTF-8'}
			}else{
				 header = {
					token,
					tokenkey,
					// 'Content-Type': 'application/x-www-form-urlencoded'
				}
			}
			// let objData = qs.stringify(data);
			axios({
					url: ADMIN_API_URL + url,
					method: 'post',
					data: data,
					headers: header
				}).then(res => {
				let oRes = res.data;
				if (oRes.code == 0) {
					if(typeof(oRes.data) === 'string') {
						Message.success(oRes.data);
					}
					resolve(oRes.data)
				} else if (oRes.code == 1) {
					Message.error(oRes.data);
					reject(oRes)
				} else if (oRes.code == -1001) { // token过期 退出登录
					Message.error(oRes.data);
					//清理一切缓存
					window.localStorage.clear();
					window.sessionStorage.clear();
					router.push({
						path: '/login'
					})
				}else{
					common.errMsg(oRes.data, oRes.msg)
				}
			}).catch(err => {
				Message.error(url)
				common.errMsg(url)
                reject(err)
            })
		})
	},
	// 下载报表请求
	getAjaxDown(url, data = {}) {
		let token = window.localStorage.getItem('token');
		let tokenkey = window.localStorage.getItem('tokenkey');
		let header = {
			token,
			tokenkey,
		}
		axios({
			url: ADMIN_API_URL + url,
			method: 'get',
			data: data,
			headers: header,
			responseType:'blob'
		}).then(res => {
			let oRes = res.data;
			let name = res.headers['content-disposition'].split('filename=').pop()
			console.log(name)
			name = decodeURI(name);
			console.log(name)
			let blob = new Blob([oRes]); //res为从后台返回的数据
			if ('msSaveOrOpenBlob' in navigator) {
				window.navigator.msSaveOrOpenBlob(blob, name);
			} else {
				const elink = document.createElement('a');
				elink.download = name;
				elink.style.display = 'none';
				elink.href = URL.createObjectURL(blob);
				elink.download = name.replace(new RegExp('"', 'g'), '')
				document.body.appendChild(elink);
				elink.click();
				URL.revokeObjectURL(elink.href);
				document.body.removeChild(elink);
			}
		}).catch(err => {
			console.log(err);
			Message.error(url)
			common.errMsg(url)
		})
	},
}
export default request;
