<template>
	<div id="app">
		<router-view v-wechat-title="$route.meta.title + ''"></router-view>
	</div>
</template>

<script>
	import Watermark from '@/libs/watermark.js';
	export default {
		computed: {
			/**
			 * 获取当前页面的用户名
			 */
			userName() {
				let oUserInfo = JSON.parse(window.localStorage.getItem('user_info'))
				const name = oUserInfo.water_mark;
				return (name && name.length > 0) ? name : ''
			}
		},
		mounted() {
			// 为当前页面增加水印
			Watermark.set(this.userName)
		}
	}
</script>
<style lang="less">

</style>
